
.whatsapp_sec1 {
  position: fixed;
  top: 85%;
  right: 4%;
  z-index: 5;
  .whatsapp_img {
    width: 100%;
    max-width: 6.313em;
  }
}

@media (max-width: 767px) {
  .whatsapp_sec1 {
    top: 82%;
    .whatsapp_img {
      max-width: 6.313em;
    }
  }
}
