.singamstv_sec1 {
  margin-top: 8em;
}

.singamstv_sec2 {
  position: relative;
  padding: 6.5625em 0 6.25em;
  .my_container {
    padding: 0;
  }
  .yellow_back_img {
    width: 100%;
    max-width: 100%;
    // height: 100%;
    min-height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    background-color: #f7c439;
  }

  .heading {
    color: #022249;
    font-size: 3.375em;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: normal;
    margin-bottom: 0.889em;
  }

  .singams_box {
    border-radius: 1.875em;
    position: relative;
    .img_wrapper {
      cursor: pointer;
    }
    .singams_img {
      border-radius: 1.875em;
      width: 100%;
      max-width: 100%;
    }
    .play_icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 8.4375em;
      object-fit: contain;
    }
    .content_wrapper {
      position: absolute;
      left: 2.5em;
      right: 2.5em;
      bottom: 2.5em;
      //   width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .video_title {
        color: #ffffff;
        font-size: 2.375em;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 1.31579;
        width: 100%;
        max-width: 25.29em;
      }
      .video_time {
        background-color: #000000;
        color: #ffffff;
        font-size: 1.75em;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: normal;
        padding: 0.079em 0.487em;
      }
    }
  }

  .vid_heading {
    margin-bottom: 0.1482em;
    margin-top: 2.223em;
  }

  .singams_row {
    margin: 0 auto;
  }

  .singams_col {
    padding: 0;
    margin-top: 2.5em;
    &:nth-child(3n + 2) .singams_box1 {
      margin: 0 auto;
    }
    &:nth-child(3n + 3) .singams_box1 {
      margin-left: auto;
    }
  }

  .singams_box1 {
    border-radius: 1.875em;
    width: 100%;
    // max-width: 96.47%;
    max-width: 95.75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .img_wrapper {
      position: relative;
      cursor: pointer;
      .singams_img {
        border-radius: 1.875em 1.875em 0em 0em;
        width: 100%;
        max-width: 100%;
      }
      .play_icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 4.125em;
        object-fit: contain;
      }
      .video_time {
        background-color: #000000;
        color: #ffffff;
        font-size: 1em;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 1.9375;
        position: absolute;
        right: 1.625em;
        bottom: 1.3125em;
        padding: 0.0625em 0.375em;
      }
    }

    .content_wrapper {
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 0em 0em 1.875em 1.875em;
      border: 1px solid #022249e6;
      padding: 1.78125em 1.875em;
      width: 100%;
      max-width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      .video_title {
        color: #022249;
        font-size: 1.375em;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 1.401;
      }
    }
  }
}

// .singamstv_lightbox_wrapper {
//   .fslightbox-fade-in-strong {
//     animation: none;
//   }
// }

@media (max-width: 991px) {
  .singamstv_sec2 {
    .singams_col {
      &:nth-child(3n + 2) .singams_box1 {
        margin: 0;
      }
      &:nth-child(3n + 3) .singams_box1 {
        margin-left: 0;
      }

      &:nth-child(2n + 1) .singams_box1 {
        padding-right: 1.2em;
      }
      &:nth-child(2n + 2) .singams_box1 {
        padding-left: 1.2em;
      }
    }

    .singams_box1 {
      max-width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .singamstv_sec1 {
    margin-top: 0;
  }

  .singamstv_sec2 {
    padding: 5.8em 0 6em;
    .my_container {
      padding: 0 15px;
    }
    .heading {
      font-size: 2.8em;
      letter-spacing: 0px;
      line-height: 1.3572;
      margin-bottom: 1.4285em;
    }
    .singams_box {
      border-radius: 1.2em;
      .singams_img {
        border-radius: 1.2em;
      }
      .play_icon {
        max-width: 6.5em;
      }
      .content_wrapper {
        left: 2em;
        right: 2em;
        bottom: 2em;
        .video_title {
          font-size: 1.6em;
          letter-spacing: 0px;
          line-height: 1.625;
          max-width: 15.125em;
        }
        .video_time {
          font-size: 1.2em;
          letter-spacing: 0px;
          line-height: normal;
          padding: 0.334em 0.791667em;
        }
      }
    }

    .vid_heading {
      margin-bottom: 0;
      margin-top: 2.1428em;
    }

    .singams_col {
      margin-top: 4em;
    }

    .singams_box1 {
      border-radius: 1.2em;
      .img_wrapper {
        .singams_img {
          border-radius: 1.2em 1.2em 0em 0em;
        }
        .play_icon {
          max-width: 6.1em;
        }
        .video_time {
          font-size: 1em;
          letter-spacing: 0px;
          line-height: normal;
          padding: 0.1em 0.45em;
        }
      }
      .content_wrapper {
        border-radius: 0em 0em 1.2em 1.2em;
        padding: 2em 2em;
        .video_title {
          font-size: 1.6em;
          letter-spacing: 0px;
          line-height: normal;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .singamstv_sec2 {
    .singams_col {
      &:nth-child(2n + 1) .singams_box1 {
        padding-right: 0;
      }
      &:nth-child(2n + 2) .singams_box1 {
        padding-left: 0;
      }
    }
  }
}
