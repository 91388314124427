.home_main {
  // position: relative;
  // background-color: #febf00;
  // z-index: -1;
  .title_wrapper {
    width: 100%;
    max-width: fit-content;
    margin: 0 auto;
    // text-align: center;
  }
  .grass_img {
    width: 100%;
    max-width: 4.7em;
    text-align: left;
  }
  .back_img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    background-color: #f7c439;
  }
}

.home_sec1 {
  // padding-top: 6.75em;
  .banner_wrapper {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    // height: calc(100vh - 6.625em);
    overflow: hidden;
    position: relative;
    .banner_img {
      width: 100%;
      max-width: 100%;
      height: 100vh;
      // height: calc(100vh - 6.625em);
      // object-fit: cover;
    }
    .banner_logo1 {
      position: absolute;
      left: 50%;
      // top: 5.8125em;
      top: 12.4375em;
      // transform: translate(-50%, -50%);
      transform: translateX(-50%);
      width: 100%;
      max-width: 19.3125em;
    }
    .banner_logo2 {
      position: absolute;
      left: 19.125em;
      top: 12.4375em;
      // transform: translateX(-50%);
      width: 100%;
      max-width: 6.625em;
    }
    .video_wrapper {
      width: 100%;
      max-width: 100%;
      height: 100vh;
      object-fit: cover;
      .video_source {
        width: 100%;
        max-width: 100%;
      }
    }
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      left: 7.25em;
      bottom: 2.375em;
      max-width: fit-content;
    }
    .swiper-pagination {
      text-align: left;
    }
    .swiper-pagination-bullet {
      border: 1px solid #ffffff;
      background: transparent;
      width: 1.4375em;
      height: 1.4375em;
      opacity: 1;
      cursor: pointer;
    }
    .swiper-pagination-bullet-active {
      border: 1px solid #febf00;
      background: #febf00;
    }
    .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-pagination-horizontal.swiper-pagination-bullets
      .swiper-pagination-bullet {
      margin: 0 0.3125em;
    }
  }
}

.home_sec2 {
  position: relative;
  // padding: 4.8125em 0 8.66em;
  padding: 4.8125em 0 15.3125em;
  //   background-color: #febf00;
  .back_img {
    background-color: #f7c439;
  }
  .big_title_wrapper {
    text-align: center;
    position: absolute;
    left: 12.175em;
    top: 5.6875em;
    .big_title {
      font-size: 5.75em;
      font-weight: 400;
    }
    .big_title_1 {
      font-size: 9.6875em;
    }
  }
  .title_wrapper {
    br {
      display: none;
    }
  }
  .details_container {
    position: relative;

    width: 100%;
    // max-width: 64.375em;
    max-width: 62.805%;
    margin-left: auto;
    margin-right: 12.125em;
    .white_back {
      width: 100%;
      // max-width: 64.375em;
      max-width: 100%;
      // height: 58.20625em;
      height: calc(100% + 4.2em);
      position: absolute;
      // left: 26.4875em;
      left: 0;
      top: 0;
      z-index: -1;
    }
    .chennai_logo {
      width: 100%;
      max-width: 23.5em;
      position: absolute;
      // left: 13.9em;
      top: 12em;
      // left: -19.75%;
      left: -12.75em;
      // top: 22.25%;
    }
    .ball_img {
      width: 100%;
      max-width: 26.71875em;
      position: absolute;
      // left: 32.75em;
      // left: 10.1%;
      left: 6.5em;
      bottom: -6em;
    }
  }
  .content_wrapper {
    width: 100%;
    // max-width: 43.8125em;
    max-width: 68.059%;
    // margin: 0 16.625em 0 42.5625em;
    // margin: 0 0 0 42.5625em;
    margin: 0 0 0 16.075em;
    padding-top: 7.76875em;
    .title_wrapper {
      margin: 0 0 1.2144em;
      text-align: left;
      br {
        display: none;
      }
    }
    .grass_img {
      width: 100%;
      max-width: 4.696em;
    }
    .desc {
      margin-bottom: 1.5em;
    }
  }
}

.home_sec3 {
  position: relative;
  padding: 3.8125em 0 8.4375em;
  .upcoming_back {
    background-color: transparent;
  }
  .white_circle {
    width: 100%;
    max-width: 1.75em;
    position: absolute;
    // left: 15.5625em;
    // top: 12.625em;
    left: 13%;
    top: 25%;
  }
  .element {
    width: 100%;
    max-width: 2.5em;
    position: absolute;
    // right: 17.5em;
    // bottom: 4.4625em;
    right: 14.5%;
    bottom: 8.75%;
  }
  .grass_img {
    max-width: 5.8em;
  }
  .channel_wrapper {
    // background-color: #ffffff;
    display: flex;
    justify-content: center;
    // padding-top: 5.5em;
    padding-top: 1.6875em;
    .title {
      color: #ffffff;
      line-height: 1.24;
    }
    .league_wrapper {
      width: 100%;
      max-width: 29.9375em;
      // margin-right: 6em;
      .league_img {
        width: 100%;
        max-width: 100%;
      }
    }
    .watch_wrapper {
      // background-color: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 10px;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      // box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
      background: rgba(255, 255, 255, 0.35);
      padding: 1.875em 3.125em 3.9375em;
      text-align: center;
      width: 100%;
      max-width: 44.8125em;
      height: 100%;
      align-self: flex-end;
      // opacity: 0.86;
      .big_title_wrapper {
        position: absolute;
        // top: -7.9375em;
        top: -7.25em;
        left: 0;
        right: 0;
      }
      .big_title {
        font-size: 6.5em;
      }
    }
    .channel_name {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2.902em;
    }
    .channel {
      display: block;
      margin-right: 3.8575em;
      pointer-events: none;
      &:last-child {
        margin-right: 0;
      }
    }
    .channel_img {
      width: 100%;
      max-width: 7.74em;
    }
    .channel_img_1 {
      width: 100%;
      max-width: 11.556em;
    }
    .jio_logo {
      width: 100%;
      max-width: 22em;
    }
    .dis_hot_logo {
      width: 100%;
      max-width: 11em;
    }
  }
}

.home_sec4 {
  position: relative;
  // padding: 7.375em 0 7.556em;
  padding: 7.375em 0 2.2144em;
  .founder_title_wrapper {
    position: absolute;
    top: 3.42em;
    left: 2.03em;
    .big_title {
      font-size: 7.9375em;
    }
  }
  .fouder_container {
    .grass_img {
      width: 100%;
      max-width: 4.7em;
    }
  }
  .fouder_container {
    // padding: 0 6.6875em 0 3.2225em;
    padding: 0 6.6875em 7.556em 2.2225em;
  }
  .founder_row {
    margin: 0 auto;
    padding-top: 4.464em;
  }
  .founder_col {
    padding: 0;
    // position: relative;
    .founder_details_wrapper {
      position: relative;
    }
    .content_back {
      position: absolute;
      left: 17%;
      top: 0;
      width: 100%;
      // max-width: 29.023em;
      // height: 29.725em;
      max-width: 78.67%;
      height: calc(100% - 2.275em);
      z-index: -1;
    }
  }

  .content_wrapper {
    display: flex;
    justify-content: flex-start;
  }
  .img_wrapper {
    width: 100%;
    max-width: 14.12em;
    // max-width: 38.303%;
    height: 27.5em;
    margin-top: 4.5em;
    .founder_img {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
  }
  .details_wrapper {
    width: 100%;
    // max-width: 17.9375em;
    max-width: 48.66%;
    margin-left: 0.375em;
    padding: 4.703em 0 0;
    .description {
      color: #000000;
      font-size: 1.125em;
      font-weight: 300;
      letter-spacing: 0px;
      line-height: 1.667;
    }
    .name {
      color: #022249;
      font-size: 1.5625em;
      font-weight: 600;
      letter-spacing: 0px;
      line-height: 1.16;
      margin: 0.8312em 0 0.3148em;
    }
    .desg {
      color: #022249;
      font-size: 1em;
      font-weight: 300;
      letter-spacing: 0px;
      line-height: 1.6875;
      padding-right: 2em;
    }
  }

  .gallery_title_wrapper {
    position: absolute;
    right: 17.3125em;
    bottom: 1.4375em;
    .big_title {
      font-size: 7.9375em;
    }
  }

  .gallery_title {
    .grass_img {
      width: 100%;
      max-width: 4.7em;
    }
  }
  .tabs_wrapper{
    width: fit-content;
    margin: auto;
    .title_wrapper{
      cursor: pointer;
      padding-right: 2em;
      &:last-child {
        padding-right: 0;
      }
    }
    .title_sec{
      padding-right: 0;
    }
  }
}

.home_sec5 {
  position: relative;
  z-index: 2;
  // background-color: #f7c439;
  // background-image: url("../../images/home/yellow_background.png");
  .fade_bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    z-index: 3;
  }
  .main_swiper {
    width: 100%;
    height: 100%;
    position: relative;

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .img_wrapper {
      width: 100%;
      max-width: 100%;
    }
    .swiper-slide {
      .main_img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .arrows_wrapper {
      position: absolute;
      left: 5.75em;
      right: 5.75em;
      top: 0;
      bottom: 0;
      z-index: 6;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .gallery_next,
      .gallery_prev {
        width: 100%;
        max-width: 3.8125em;
        cursor: pointer;
      }
    }
  }

  .thumb_swiper {
    width: 100%;
    max-width: 73.95em;
    // height: 100%;
    // height: fit-content;
    margin-left: auto;
    margin-right: auto;
    // padding: 2em 5em;
    padding: 2em 5em 0;
    position: absolute;
    left: 0;
    right: 0;
    // bottom: 0.25em;
    bottom: -3.25em;
    z-index: 5;
    overflow: hidden;
    .swiper-slide {
      background-size: cover;
      background-position: center;
      // width: 25%;
      width: 11.6875em;
      width: auto;
      height: 100%;
      max-height: 6.8125em;
      min-height: 6.8125em;
      opacity: 0.4;
      transform: scale(1);
      margin: 1em 0 0;
      text-align: center;
      // margin: 1em auto 0;
      .thumb_img {
        border: 2px solid transparent;
        width: 100%;
        max-width: 100%;
        // width: auto;
        height: 100%;
        max-height: 6.8125em;
        min-height: 6.8125em;
        border-radius: 10px;
        object-fit: cover;
        display: block;
        object-fit: fill;
        // margin: 0 auto;
        // text-align: center;
        cursor: pointer;
      }
    }
    .swiper-slide-thumb-active {
      opacity: 1;
      transform: scale(1.12965);
      margin-right: 52px !important;
      margin-left: 30px;
      margin-bottom: 1em;
      margin-top: 0;
      .thumb_img {
        transform: scale(1.12965);
        border: 2px solid #022249;
      }
    }
  }
}

// pagination slider start

// .home_sec5 {
//   .gallery_container {
//     .gallery_slider {
//       position: relative;
//       width: 100%;
//       height: 100%;

//       .swiper-slide {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//       }

//       .swiper-slide {
//         img {
//           display: block;
//           width: 100%;
//           height: 100%;
//           object-fit: cover;
//         }
//       }

//       .swiper-pagination {
//         display: flex;
//         justify-content: center;
//         align-items: flex-end;
//         flex-wrap: wrap;
//       }

//       .swiper-pagination-bullet {
//         background-color: transparent;
//         width: 11.6875em;
//         height: 6.8125em;
//         border-radius: 10px;
//         margin: 0 0.6875em;
//         // text-align: center;
//         // line-height: 20px;
//         // font-size: 12px;
//         // color: #000;
//         // opacity: 1;
//         // background: rgba(0, 0, 0, 0.2);
//         opacity: 1;
//         .bullet_img {
//           border-radius: 10px;
//           width: 100%;
//           max-width: 100%;
//           height: 100%;
//         }
//       }

//       .swiper-pagination-bullet-active {
//         // color: #fff;
//         // background: #007aff;
//         background-color: transparent;
//         width: 14.8125em;
//         height: 9.4375em;
//         margin: 0 0.9375em;
//         opacity: 1;
//         .bullet_img {
//           border: 1px solid red;
//         }
//       }

//     }
//     .arrows_wrapper {
//       position: absolute;
//       left: 5.75em;
//       right: 5.75em;
//       top: 0;
//       bottom: 0;
//       z-index: 2;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       .gallery_next,
//       .gallery_prev {
//         width: 100%;
//         max-width: 3.8125em;
//         cursor: pointer;
//       }
//     }
//   }
// }

// pagination slider end

.home_sec6 {
  position: relative;
  // padding: 8.25em 0 6.875em;
  padding: 11.75em 0 6.875em;
  overflow: hidden;
  .contact_title_wrapper {
    position: absolute;
    // top: 0;
    top: 5.75em;
    left: 10.3125em;
    .big_title {
      font-size: 7.9375em;
    }
  }
  // .title_wrapper {
  //   padding-top: 1.25em;
  // }
  .contact_row {
    margin: 3.402em auto 0;
    width: 100%;
    max-width: 72.5em;
  }
  .contact_col {
    padding: 0;
    &:nth-child(2n + 1) {
      .input_wrapper {
        padding-right: 1.625em;
      }
    }
    &:nth-child(2n + 2) {
      .input_wrapper {
        padding-left: 1.625em;
      }
    }
    &:last-child {
      .input_wrapper {
        padding: 0;
      }
    }
    // .input_wrapper {
    //   padding-bottom: 1.875em;
    // }
    .bat_img {
      width: 100%;
      max-width: 8.603em;
      position: absolute;
      right: -6.5em;
      top: 0;
    }
  }
  .btn_wrapper {
    width: 100%;
    max-width: fit-content;
    margin: 2.75em auto 0;
  }
  .thank_msg {
    // color: #000000;
    color: #022249;
    font-size: 1.5em;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 1.583;
    text-align: center;
    margin: 1.25em 0 0;
  }
}

.home_sec7 {
  position: relative;
  // padding: 1.3125em 0 8.456em;
  padding: 1.3125em 0 0;
  // z-index: 99;
  .s_title_wrapper {
    position: absolute;
    right: 9.625em;
    top: 0;
    .big_title {
      font-size: 7.9375em;
    }
  }
  .s_container {
    position: relative;
    // margin-top: 5.1612em;
    margin-top: -3em;
    // z-index: 99;
    .swiper {
      appearance: none;
      -webkit-appearance: none;
      .swiper-wrapper {
        appearance: none;
        -webkit-appearance: none;
        align-items: center;
        .company_logo {
          position: relative;
          width: 100%;
          max-width: 100%;
          // max-width: 15em;
          object-fit: cover;
        }
      }
    }
  }
}

// @media (min-width: 2560px) {
//   .home_sec4 {
//     .fouder_container {
//       padding: 0 16.6875em 7.556em 6.2225em;
//       width: 100%;
//       max-width: 90%;
//     }
//   }
// }

// @media (max-width: 1700px) {
//   .home_sec2 {
//     .details_container {
//       .chennai_logo {
//         left: -16.5%;
//       }
//     }
//   }
// }

// @media (max-width: 1620px) {
//   .home_sec2 {
//     .details_container {
//       .chennai_logo {
//         left: -17.9%;
//       }
//     }
//   }
// }

// @media (max-width: 1620px) {
//   .home_sec2 {
//     .details_container {
//       .chennai_logo {
//         left: -17.9%;
//       }
//     }
//   }
// }

// @media (max-width: 1620px) {
//   .home_sec2 {
//     .details_container {
//       .chennai_logo {
//         left: -16.25%;
//       }
//     }
//   }
// }

@media (max-width: 1194px) {
  .home_sec4 {
    .founder_col {
      .content_back {
        max-width: 85%;
      }
    }
    .details_wrapper {
      max-width: 55%;
    }
  }
}

@media (max-width: 1080px) {
  .home_sec2 {
    .big_title_wrapper {
      left: 2.5em;
    }

    // .content_wrapper {
    //   margin: 0 0 0 32.5em;
    // }
    // .details_container {
    //   .white_back {
    //     left: 18.4875em;
    //     max-width: 62.5em;
    //   }
    //   .chennai_logo {
    //     max-width: 21.5em;
    //     left: 6.9em;
    //   }
    //   .ball_img {
    //     max-width: 24.71875em;
    //     bottom: -4em;
    //     left: 23.5em;
    //   }
    // }
  }

  .home_sec4 {
    .fouder_container {
      padding: 0 4em 7.556em 1em;
    }
  }

  .home_sec5 {
    .main_swiper {
      .arrows_wrapper {
        left: 1.5em;
        right: 1.5em;
      }
    }
  }
  .home_sec7 {
    .s_title_wrapper {
      .big_title {
        font-size: 6.5em;
      }
    }
  }
}

@media (max-width: 992px) {
  .home_sec1 {
    .banner_wrapper {
      height: 100%;
      .banner_img {
        height: 100%;
      }
      .banner_logo1 {
        top: 18%;
        max-width: 15%;
      }
      .banner_logo2 {
        top: 18%;
      }
    }
  }

  .home_sec2 {
    .content_wrapper {
      // margin: 0 0 0 22em;
      margin: 0 0 0 13em;
      max-width: 70%;
    }
    .details_container {
      max-width: 92%;
      margin-right: -2em;
      // .white_back {
      //   left: 8.4875em;
      // }
      // .chennai_logo {
      //   left: -3em;
      // }
      .ball_img {
        // left: 12.9em;
        left: 3em;
      }
    }
  }

  .home_sec4 {
    .gallery_title_wrapper {
      right: 7.3125em;
    }
  }
}

@media (max-width: 991px) {
  .home_sec2 {
    .big_title_wrapper {
      left: 1.5em;
      .big_title {
        font-size: 4.5em;
      }
      .big_title_1 {
        font-size: 8.5em;
      }
    }
  }
  .home_sec4 {
    .founder_title_wrapper {
      left: 1.5em;
      .big_title {
        font-size: 6.25em;
      }
    }
    .gallery_title_wrapper {
      right: 1.5em;
      .big_title {
        font-size: 6.25em;
      }
    }
    .founder_row {
      justify-content: center;
    }
    .founder_col {
      &:last-child {
        margin-top: 3em;
      }
    }
  }

  .home_sec6 {
    .contact_title_wrapper {
      left: 1.5em;
      .big_title {
        font-size: 6.25em;
      }
    }
    .contact_row {
      max-width: 100%;
    }
    .contact_col {
      &:nth-child(2n + 1) {
        .input_wrapper {
          padding-right: 0;
        }
      }
      &:nth-child(2n + 2) {
        .input_wrapper {
          padding-left: 0;
        }
      }
    }
  }

  .home_sec7 {
    .s_title_wrapper {
      right: 1em;
      .big_title {
        font-size: 6.25em;
      }
    }
  }
}

@media (max-width: 767px) {
  .home_main {
    .grass_img {
      max-width: 5.427em;
    }
  }

  .home_sec1 {
    padding-top: 5.8em;
    .banner_wrapper {
      height: calc(100vh - 5.8em);
      .banner_img {
        height: calc(100vh - 5.8em);
      }
      .banner_logo1 {
        max-width: 17.5em;
        // top: 14.75em;
        // top: 19.5%;
        top: 18%;
      }
      .banner_logo2 {
        max-width: 5.4em;
        // top: 11.2em;
        top: 5.2em;
        left: 1.6em;
      }
      .swiper-pagination-bullet {
        width: 1.7em;
        height: 1.7em;
      }
      .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
      .swiper-pagination-horizontal.swiper-pagination-bullets
        .swiper-pagination-bullet {
        margin: 0 0.4em;
      }

      .swiper-pagination-fraction,
      .home_sec1 .banner_wrapper .swiper-pagination-custom,
      .swiper-horizontal > .swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal {
        left: 1.6em;
        bottom: 2.1em;
        // bottom: 11.5em;
      }
    }
  }

  .home_sec2 {
    padding: 12.224em 0 10.6em;
    .big_title_wrapper {
      top: 2.26em;
      left: 1.6em;
      .big_title {
        font-size: 3.5em;
      }
      .big_title_1 {
        font-size: 7.3em;
      }
    }

    .content_wrapper {
      // margin: 0 0 0 3.3em;
      margin: 0;
      max-width: 100%;
      padding: 13.076em 2em 0 3.3em;
      .title_wrapper {
        br {
          display: block;
        }
      }
    }

    .details_container {
      max-width: 100%;
      margin-right: 0;
      .white_back {
        // max-width: 34.5em;
        max-width: 100%;
        height: 88.616em;
        height: calc(100% + 4.5em);
        left: 0;
      }
      .chennai_logo {
        max-width: 12.328em;
        left: 50%;
        top: -4%;
        transform: translateX(-50%);
      }
      .ball_img {
        max-width: 28.1em;
        left: 2.9em;
        bottom: -5.5em;
      }
    }
  }

  .home_sec3 {
    padding: 4.6em 0 5.5em;
    .channel_wrapper {
      flex-direction: column;
      align-items: center;
      padding-top: 0;
      .league_wrapper {
        margin-right: 0;
        max-width: 25.865em;
      }
      .watch_wrapper {
        padding: 1.8em 2.42em 3.248em 1.6em;
        // margin-top: 3.14em;
        margin: 3.14em auto 0;
      }
      .channel {
        // margin-right: 3.055em;
        margin-right: 2.9em;
      }
      .channel_img {
        max-width: 6.137em;
      }
      .channel_img_1 {
        max-width: 9.164em;
      }
      .jio_logo {
        width: 100%;
        max-width: 18em;
      }
      .dis_hot_logo {
        width: 100%;
        max-width: 9em;
      }
    }
  }

  .home_sec4 {
    padding: 4.5em 0 1.67em;
    .founder_title_wrapper {
      left: initial;
      right: 0;
      top: 0;
      .big_title {
        font-size: 3.7em;
      }
    }
    .fouder_container {
      padding: 0 1.5em 7em 1.5em;
    }
    .founder_row {
      padding-top: 1.77em;
    }
    .founder_col {
      // margin-top: 4.3em;
      margin-top: 5em;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-top: 5em;
      }
      .content_back {
        // max-width: 30.55em;
        max-width: 90%;
        // height: 40em;
        height: 100%;
        // left: 1.975em;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .founder_details_wrapper {
      width: 100%;
      // max-width: 50%;
      max-width: 36em;
      margin: 0 auto;
    }
    .img_wrapper {
      max-width: 12.573em;
      height: 24.4em;
      height: 60.7%;
      position: absolute;
      right: 0;
      bottom: -1.75em;
      margin-top: 0;
    }
    .details_wrapper {
      // max-width: 100%;
      max-width: 22em;
      margin-left: 5.071em;
      padding: 4.5em 0 2.5em;
      .description {
        font-size: 1.6em;
        line-height: 1.625;
      }
      .name {
        font-size: 1.8em;
        line-height: 1.445;
        margin: 1em 0 0.334em;
      }
      .desg {
        font-size: 1.6em;
        line-height: 1.5;
        padding-right: 0;
      }
    }

    .gallery_title_wrapper {
      right: initial;
      left: 0;
      bottom: 8em;
      .big_title {
        font-size: 3.7em;
      }
    }
  }

  .home_sec5 {
    .main_swiper {
      .arrows_wrapper {
        left: 1.5em;
        right: 1.5em;
      }
    }

    .fade_bg {
      height: 44.5em;
    }

    .main_swiper {
      .swiper-slide {
        .main_img {
          min-height: 66.1em;
        }
      }
    }

    .thumb_swiper {
      // height: 10em;
      bottom: 0.5em;
      .swiper-slide-thumb-active {
        margin-right: 35px !important;
        margin-left: 20px;
        .thumb_img {
          border: 1px solid #022249;
        }
      }
    }
  }

  .home_sec6 {
    // padding: 2.25em 0 4em;
    padding: 6.3em 0 4em;
    .contact_title_wrapper {
      left: initial;
      right: 0;
      // top: 0;
      top: 2em;
      .big_title {
        font-size: 3.7em;
      }
    }
    .btn_wrapper {
      margin: 2.9em auto 0;
    }
    .thank_msg {
      font-size: 1.6em;
      letter-spacing: 0px;
      line-height: 1.625;
      margin: 1.1em 0 0;
      padding: 0 2.5em;
    }
  }

  .contact_cta {
    .btn_wrapper {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      z-index: 11;
      .submit_btn {
        font-size: 2.6em;
        line-height: 1.5;
        border: none;
        border-radius: 0;
        min-width: 100%;
        max-width: 100%;
        // padding: 0.4039em 1em;
        padding: 0.212em 1em;
        background-color: #022249;
        color: #ffffff;
      }
    }
  }

  .home_sec7 {
    // padding: 3em 0 4em;
    padding: 3em 0 0;
    .s_title_wrapper {
      right: initial;
      left: 0;
      top: 0;
      .big_title {
        font-size: 3.7em;
      }
    }
    .s_container {
      // margin-top: 3.5em;
      margin-top: -2em;
      // min-height: 7.5em;
      .swiper-slide {
        .company_logo {
          max-width: 15em;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .home_sec4 {
    .founder_details_wrapper {
      max-width: 100%;
    }
    .founder_col {
      .content_back {
        max-width: 30.55em;
        left: 1.975em;
        transform: translateX(0);
      }
    }
  }
}

@media (max-width: 361px) {
  .home_sec4 {
    .founder_col {
      .content_back {
        left: 1.17em;
      }
    }
    .details_wrapper {
      max-width: 19em;
    }
  }
}

@media (max-width: 355px) {
  .home_sec2 {
    overflow: hidden;
    .details_container {
      .white_back {
        height: calc(100% + 3.75em);
      }
      .ball_img {
        max-width: 23.1em;
        // left: 1.5em;
        bottom: -4.5em;
      }
    }
  }
}

@media (max-width: 350px) {
  .home_sec4 {
    .founder_col {
      .content_back {
        left: 0;
      }
    }
  }
  .home_sec5{
    .tabs_wrapper{
      // padding-right: .5em;
      .title_sec{
        padding-right: 0;
      }
      // & :nth-child(1){
      //   padding-right: 0.5em;
      // }
    }
  }
}
