.stats_main {
  .yellow_back_img {
    width: 100%;
    max-width: 100%;
    // height: 100%;
    min-height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -2;
    background-color: #f7c439;
  }
}

.stats_sec1 {
  margin-top: 6.6em;
}

.stats_sec2,
.stats_sec3 {
  position: relative;
  padding: 6.5625em 0 9.28375em;
  //   padding: 6.5625em 0 0;
  .stats_box {
    // &::before {
    //   content: "";
    //   background-color: #ffffff;
    //   width: 100%;
    //   max-width: 95.8335%;
    //   height: 1px;
    //   margin: 0 auto;
    //   position: absolute;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    // }
    .stats_row {
      margin: 0 auto;
    }
    .stats_col {
      padding: 0;
      &:nth-child(2n + 1) .stats_wrapper {
        margin-right: 2.5em;
      }
      &:nth-child(2n + 2) .stats_wrapper {
        margin-left: 2.5em;
      }
      &:nth-child(1) .stats_wrapper,
      &:nth-child(2) .stats_wrapper {
        margin-top: 6.0625em;
      }
    }
    .stats_wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      //   margin: 0 1.9em 0 0.6em;
      //   margin-right: 1.9em;
      padding-left: 0.6em;
      margin-top: 6.5625em;
      .stats_white_back {
        position: absolute;
        right: 1.7em;
        bottom: 0;
        width: 100%;
        // max-width: 44.875em;
        max-width: 84.75%;
        height: 10.5em;
        z-index: -1;
      }
    }
    .numb {
      //   color: #ffffff;
      font-size: 14.1875em;
      font-weight: 500;
      letter-spacing: 0px;
      //   line-height: 0.2247;
      line-height: 0.92;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke: 1px #ffffff;
      background-clip: text;
      -webkit-background-clip: text;
      opacity: 0.8;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
    }
    .heading_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 3.375em;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: normal;
      }
      .tab_name {
        border: 1px solid #022249;
        border-radius: 50px;
        background-color: transparent;
        color: #022249;
        font-size: 1.5em;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 1.285;
        // padding: 0.4704em 1.4795em;
        padding: 0.4704em 0.5em;
        transition: 0.5s ease-in-out;
        &:nth-child(1) {
          margin-right: 1.5em;
          min-width: 6.54167em;
        }
        &:nth-child(2) {
          min-width: 10.08334em;
        }
        &.active {
          background-color: #022249;
          border: 1px solid #022249;
          color: #ffffff;
          transition: 0.5s ease-in-out;
        }
      }
    }
    .played_container {
      display: flex;
      // align-items: center;
      padding-top: 13.5%;
    }
    .played_wrapper {
      width: 100%;
      //   max-width: 4.9375em;
      max-width: 28.735%;
      text-align: center;

      &:nth-child(3) {
        // min-width: 6em;
        min-width: 34.92%;
        max-width: fit-content;
      }
    }
    .played_divider {
      background-color: #707070;
      width: 2px;
      height: 2.4375em;
      margin: 0 1.6432em 0 1.7319em;
      //   margin: 0 11.902% 0 12.545%;
      align-self: center;
    }
    .player_img {
      width: 100%;
      max-width: 16.171em;
      //   max-width: 32.4777%;
    }
    .player_name {
      color: #022249;
      font-size: 2.375em;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 1.29;
      width: 100%;
      //   max-width: 4.5em;
      max-width: 21.526%;
      //   margin: 0 0.79em 0 0.7529em;
      margin: 0 3.735% 0 3.56%;
      //   padding-top: 2.215em;
      padding-top: 12.25%;
      text-transform: uppercase;
    }
    .played_numb {
      color: #000000;
      font-size: 2.375em;
      font-weight: 500;
      letter-spacing: 0px;
      // line-height: 0.552;
      line-height: 1.29;
    }
    .played_title {
      color: #000000;
      font-size: 1.25em;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 1.3;
    }
  }
}

.stats_sec3 {
  position: relative;
  padding: 6.5625em 0 7.375em;
  .stats_box {
    &::before {
      content: "";
      background-color: #ffffff;
      width: 100%;
      max-width: 95.8335%;
      height: 1px;
      margin: 0 auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
    }
  }
  .divider_wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: -1.5em;
    text-align: center;
    z-index: 1;
    .bat_img {
      width: 100%;
      max-width: 22.267%;
    }
  }
}

@media (max-width: 1920px) {
  .stats_sec2 {
    .my_container_stat {
      max-width: 1718px;
    }
  }
}

@media (max-width: 1700px) {
  .stats_sec2,
  .stats_sec3 {
    .stats_box {
      .played_container {
        padding-top: 10%;
      }
      .player_name {
        padding-top: 9%;
      }
    }
  }
}

@media (max-width: 1440px) {
  .stats_sec2,
  .stats_sec3 {
    .stats_box {
      .played_container {
        padding-top: 12%;
      }
      .player_name {
        padding-top: 11%;
      }
    }
  }
}

@media (max-width: 1194px) {
  .stats_sec2,
  .stats_sec3 {
    .stats_box {
      .stats_wrapper {
        .stats_white_back {
          right: 0;
          max-width: 90%;
        }
      }
      .stats_col {
        &:nth-child(2n + 1) .stats_wrapper {
          margin-right: 1em;
        }
        &:nth-child(2n + 2) .stats_wrapper {
          margin-left: 1em;
        }
      }
      .player_name {
        margin: 0 0.25em 0 0.5em;
      }
      .played_divider {
        margin: 0 1em 0 1em;
      }
    }
  }
}

@media (max-width: 992px) {
  .stats_sec2,
  .stats_sec3 {
    .stats_box {
      .stats_col {
        flex: 0 0 100%;
      }
      .player_img {
        max-width: 18em;
      }
      .player_name {
        margin: 0 1.5em 0 1.5em;
        max-width: 24%;
      }
      .played_wrapper {
        max-width: 35%;
      }
      .played_wrapper {
        &:nth-child(3) {
          min-width: 37%;
        }
      }
      .played_divider {
        margin: 0 2em 0 2em;
      }
      .stats_wrapper {
        .stats_white_back {
          right: 1em;
          height: 11.25em;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .stats_sec2,
  .stats_sec3 {
    .stats_box {
      .stats_col {
        flex: 0 0 auto;
      }
    }
  }
}

@media (max-width: 767px) {
  .stats_sec1 {
    margin-top: 0;
  }
  .stats_sec2,
  .stats_sec3 {
    padding: 5.2em 0 9.193em;

    .stats_box {
      // .stats_row {
      //   width: 100%;
      //   max-width: 30.374em;
      // }
      .stats_col {
        &:nth-child(2n + 1) .stats_wrapper {
          // margin-right: 0;
          margin: 4.03em auto 0;
        }
        &:nth-child(2n + 2) .stats_wrapper {
          // margin-left: 0;
          margin: 4.03em auto 0;
        }
        &:nth-child(1) .stats_wrapper {
          margin-top: 4.03em;
        }
        &:nth-child(2) .stats_wrapper {
          margin-top: 4.03em;
        }
      }
      .heading_container {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 2.8em;
        .title {
          font-size: 2.8em;
          letter-spacing: 0px;
          line-height: 1.357;
          margin-bottom: 1.316em;
        }
        .tab_name {
          font-size: 1.8em;
          letter-spacing: 0px;
          line-height: 1.445;
          // padding: 0.567em 1.4545em;
          padding: 0.567em 0.5em;
          &:nth-child(1) {
            margin-right: 1em;
            min-width: 7.2778em;
          }
          &:nth-child(2) {
            min-width: 10.0556em;
          }
        }
      }
      .numb {
        font-size: 22.7em;
        letter-spacing: 0px;
        line-height: normal;
        bottom: initial;
        top: -0.3em;
        left: -0.15em;
        z-index: -2;
      }
      .stats_wrapper {
        flex-direction: column;
        margin-top: 4.03em;
        width: 100%;
        max-width: 85%;
        padding-left: 0;
        .stats_white_back {
          // height: auto;
          height: 36.988em;
          right: 0;
          left: 0;
          max-width: 100%;
        }
      }
      .player_img {
        max-width: 25.7em;
      }
      .player_name {
        font-size: 2.8em;
        letter-spacing: 0px;
        line-height: 1.321;
        max-width: 100%;
        margin: 0.59em 0 0.345em;
        padding-top: 0;
        text-align: center;
      }
      .played_container {
        padding-top: 0;
        margin-bottom: 2.168em;
        justify-content: center;
      }
      .played_wrapper {
        max-width: 33%;
        &:nth-child(3) {
          max-width: 33%;
        }
      }
      .played_numb {
        font-size: 2.8em;
        letter-spacing: 0px;
        line-height: 1.321;
      }
      .played_title {
        font-size: 1.6em;
        letter-spacing: 0px;
        line-height: 1.5;
        font-weight: 500;
      }
      .played_divider {
        height: 3.283em;
        margin: 0 1.85em 0 2.15em;
      }
    }
  }

  .stats_sec3 {
    .stats_box {
      &::before {
        max-width: 91.42%;
      }
    }
    .divider_wrapper {
      top: -1.25em;
      .bat_img {
        max-width: 22.72em;
      }
    }
  }
}

@media (max-width: 576px) {
  .stats_sec2,
  .stats_sec3 {
    // padding: 5.2em 2.063em 9.193em;
    .stats_box {
      .stats_row {
        padding: 0 2.063em;
      }
    }
  }
}

@media (max-width: 450px) {
  .stats_sec2,
  .stats_sec3 {
    .stats_box {
      .stats_wrapper {
        max-width: 100%;
        .stats_white_back {
          height: 35em;
        }
      }
    }
  }
}
