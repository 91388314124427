/*============================
     Colors    
============================*/
$color-FFFFFF: #ffffff;
$color-000000: #000000;

body {
  font-family: "Oswald", sans-serif;
  // height: 100dvh;
}

@font-face {
  font-family: "impact";
  src: url("./Fonts/IMPACT.TTF");
  font-weight: normal;
  font-style: normal;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #545353;
}
::-webkit-scrollbar-thumb {
  background-color: #f7c439;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
figure {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  background: none;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
  &:hover {
    color: inherit;
  }
}
// .row {
//   margin-left: 0;
//   margin-right: 0;
// }
:focus {
  outline: none;
  outline: -webkit-focus-ring-color auto 0px;
}

::-moz-focus-inner {
  border: 0;
}
button:focus,
textarea:focus,
input:focus {
  outline: none;
}

li {
  list-style-type: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #00000080 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

@media (min-width: 2561px) {
  body {
    font-size: 18px;
  }
  .my_container {
    max-width: 2226px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (min-width: 1921px) {
  .my_container {
    max-width: 1721px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 1920px) {
  body {
    font-size: 16px;
  }
  .my_container {
    max-width: 1670px;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1700px) {
  body {
    font-size: 13px;
  }
  .my_container {
    max-width: 1619px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 1620px) {
  body {
    font-size: 13px;
  }
  .my_container {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 1536px) {
  body {
    font-size: 11px;
  }
  .my_container {
    max-width: 1356px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}
@media (max-width: 1440px) {
  .my_container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 1366px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 1194px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 1280px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 1113px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1194px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 1031px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 1080px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 931px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 992px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 767px) {
  .my_container {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

.title {
  position: relative;
  color: #022249;
  font-size: 3.375em;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 1.482;
  // &::after {
  //   content: url("././images/home/title_bottom.png");
  //   // width: 100%;
  //   // max-width: 4.696em;
  //   transform: scale(0.6);
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  // }
}

.desc {
  color: #000000;
  font-size: 1.5em;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 1.583;
}

.big_title {
  color: #ffffff;
  font-size: 5.75em;
  font-weight: 400;
  font-family: "impact", sans-serif;
  letter-spacing: 0px;
  line-height: 1;
  opacity: 0.39;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  background-clip: text;
  -webkit-background-clip: text;
  text-transform: uppercase;
}

.input_wrapper {
  position: relative;
  // padding-bottom: 1.875em;
  padding-bottom: 2.5em;
  .form_input {
    appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    border: none;
    // border: 1px solid #022249e6;
    border: 1px solid #022249;
    // border-radius: 20px;
    border-radius: 0.8334em;
    // color: #000000;
    color: #00000080;
    font-size: 1.5em;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 1.125;
    display: block;
    outline: none;
    padding: 1.071em 1em 1.071em 2.42375em;
    width: 100%;
    max-width: 100%;
    opacity: 0.9;
  }
  .form_select {
    // background: url("") no-repeat;
    // background-position: calc(100% - 1.25em) center;
    // background-size: 1em;
    cursor: pointer;
  }
  .form_textarea {
    position: relative;
    z-index: 2;
    height: 9.20834em;
    resize: none;
  }
  .error {
    position: absolute;
    left: initial;
    color: red;
    font-size: 1.125em;
    font-weight: 400;
    top: calc(100% - 2em);
  }
}

.submit_btn {
  border: none;
  // background-color: #022249;
  background-color: #ffffff;
  border: 1px solid #022249;
  border-radius: 50px;
  // color: #ffffff;
  color: #022249;
  font-size: 1.5em;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.5;
  padding: 0.563em 1.6875em;
  min-width: 7.334em;
  transition: background-color 0.5s ease;
  &:hover {
    background-color: #022249;
    color: #ffffff;
    transition: background-color 0.5s ease;
  }
}

@media (max-width: 767px) {
  .input_wrapper {
    padding-bottom: 1.9em;
    .form_input {
      border-radius: 18px;
      font-size: 1.6em;
      line-height: 1.5;
      padding: 0.95em 1em 0.95em 2.3125em;
    }
    .form_textarea {
      height: 11.25em;
    }
    .error {
      top: calc(100% - 1.7em);
    }
  }
  .submit_btn {
    font-size: 1.6em;
    line-height: 1.5;
    padding: 0.75em 1em;
    min-width: 10.3125em;
  }
  .title {
    font-size: 2.8em;
    letter-spacing: 0px;
    line-height: normal;
  }
  .desc {
    font-size: 1.6em;
    letter-spacing: 0px;
    line-height: 1.625;
  }
  .big_title {
    font-size: 3.7em;
    line-height: 1;
  }
}
