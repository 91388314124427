@media (max-width: 767px) {
  .contact_cta {
    .btn_wrapper {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      z-index: 11;
      .submit_btn {
        font-size: 2.6em;
        line-height: 1.5;
        border: none;
        border-radius: 0;
        min-width: 100%;
        max-width: 100%;
        // padding: 0.4039em 1em;
        padding: 0.212em 1em;
        background-color: #022249;
        color: #ffffff;
      }
    }
  }
}
