.header_sec1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 99;
  //   padding: 2.163em 0;
  padding: 1.538em 0;
  border-bottom: 1px solid #ffffff;
  &.navbar_bg {
    background: rgba(255, 255, 255, 0.057);
    backdrop-filter: blur(7px);
    box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid transparent;
  }
  .navlinks {
    position: relative;
    ul {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-width: 50%;
      float: right;
      .logo_wrapper {
        position: absolute;
        // top: -1.25em;
        top: -0.8em;
        bottom: 0;
        margin: auto;
        // left: -3.15em;
        left: 0;
        .logo_img {
          position: relative;
          max-width: 5.375em;
        }
      }
      .nav_tab {
        position: relative;
        color: #1b1b1b;
        white-space: nowrap;
        cursor: pointer;
        text-align: center;
        margin: 0 0 0 2.5em;
        font-size: 1.5em;
        font-weight: 400;
        line-height: 1.5;
        color: #022249;
        position: relative;
        // &::after {
        //   content: "";
        //   background: url("../../images/home/title_bottom.png");
        //   background-size: 100% 100%;
        //   display: none;
        //   width: 2.4em;
        //   height: 1.25em;
        //   left: 0;
        //   bottom: -0.9em;
        //   position: absolute;
        // }
        &:hover {
          text-decoration: none;
          //   font-weight: 600;
          transition: 0.3s ease;
          // &::after {
          //   width: 2.5em;
          //   transition: 0.3s ease;
          // }
        }
        .submit_btn {
          font-size: 1em;
          // background-color: #ffffff;
          // border: 1px solid #022249;
          // color: #022249;
          padding: 0.3835em 1em;
          min-width: 6.41667em;
        }
      }
      .nav_tab.active {
        font-weight: 400;
        // &::after {
        //   width: 2.5em;
        // }
      }
      .nav_tab_1 {
        &::after {
          content: "";
          background: url("../../images/home/title_bottom.png");
          background-size: 100% 100%;
          display: none;
          width: 2.4em;
          height: 1.25em;
          left: 0;
          bottom: -0.9em;
          position: absolute;
        }
      }
      .nav_tab_1.active {
        font-weight: 400;
        &::after {
          display: block;
        }
      }
      .nav_btn {
        position: relative;
        .nav_img {
          display: none;
          position: absolute;
          left: 3.6em;
          bottom: -1.4em;
          width: 100%;
          max-width: 3.6em;
        }
      }
      .nav_btn_active {
        .nav_img {
          display: block;
        }
        .nav_tab {
          font-weight: 400;
        }
      }
      .no_active {
        .nav_img {
          display: none;
        }
      }
      .nav_btn.active {
        .nav_img {
          display: block;
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  .header_sec1 {
    height: 6em;
    .logo_wrapper {
      // position: absolute;
      .ispl_logo {
        position: absolute;
        left: initial;
        top: 1em;
        width: 100%;
        max-width: 5.291em;
        // margin-left: -1.5em;
        margin-left: 0;
      }
    }

    .nav_line2_wrapper_flex_wrapper {
      position: fixed;
      top: 0;
      left: -100%;
      background-color: #ffffff;
      // background-color: #020202;
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      transition: 0.3s left;
      &.active {
        left: 0;
      }
      .logo_wrapper {
        position: absolute;
        max-height: 10vh;
        top: 1em;
        margin: auto;
        left: 1.5em;
        z-index: 9;
        .ispl_logo_1 {
          width: 100%;
          max-width: 5.291em;
          // margin-left: 1.5em;
          // padding: 2em 0;
        }
      }
      .nav_social_media_icons {
        position: absolute;
        bottom: 15%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        text-align: left;
        padding-left: 1.5em;

        .nav_social_icons {
          display: none;
          width: 100%;
          max-width: 2em;
          height: 2em;
          object-fit: cover;
          margin: 0 2em 0 0;
        }
        a:last-child {
          .nav_social_icons {
            margin-right: 0;
          }
        }
        .follow_us_text {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          font-size: 1.6em;
          text-align: center;
          margin-top: 1.5em;
          a {
            color: #000000;
          }
        }
      }
    }

    .nav_line2_wrapper_flex {
      // background-color: #020202;
      background: #f7c439 url("../../images/general/mb_yellow_texture.png")
        no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      height: 100%;
      overflow-y: auto;
      width: 100%;
      margin: 6em 0 0;
      padding: 10em 1.5em 0;
      // position: relative;
      &::-webkit-scrollbar {
        width: 5px;
        background-color: #000000;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #000000;
      }
      .nav_item {
        text-align: left;
        width: 100%;
        position: relative;
        cursor: pointer;
        margin-left: 0;
        padding-left: 4.1em;
        margin-bottom: 2.4em;
      }
      .nav_link_wrapper {
        position: relative;
        width: fit-content;
        margin: 0 auto;
      }
      .nav_link {
        cursor: pointer;
        color: #022249;
        text-transform: capitalize;
        font-size: 2.6em;
        font-weight: 400;
        line-height: 1.23;
        opacity: 1;
        display: block;
        text-align: left;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .not_active_arrow {
          display: block;
          width: 100%;
          max-width: 1.027em;
          object-fit: contain;
        }
        .active_arrow {
          display: none;
        }
      }
      .nav_link.active,
      .nav_btn_active {
        position: relative;
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        color: #022249;
        .not_active_arrow {
          display: none;
        }
        .active_arrow {
          display: block;
          width: 100%;
          max-width: 1.027em;
          object-fit: contain;
        }
      }
      .nav_linkbtn {
        color: #ffffff;
        border: none;
        border-radius: 10px;
        background: transparent linear-gradient(90deg, #b20710 0%, #ee2c3c 100%)
          0% 0% no-repeat padding-box;
        padding: 0.75em 1.25em 0.75em 0.75em;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-width: 9em;
        font-weight: 600;
      }
      // .nav_btn {
      //   &.btn_active {
      //     color: green;
      //   }
      // }
      .btn_wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        .submit_btn {
          font-size: 1em;
          line-height: 1.5;
          border: none;
          border-radius: 0;
          min-width: 100%;
          max-width: 100%;
          padding: 0.4039em 1em;
          background-color: #022249;
          color: #ffffff;
        }
      }
    }

    .hamburger_lines {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1.5em;
      width: 2.716em;
      height: 1.237em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      .line,
      .line1,
      .line2,
      .line3 {
        height: 2px;
        width: 100%;
        border-radius: 1em;
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
        background-color: #022249;
      }
      // .line1 {
      //   max-width: 75%;
      //   -webkit-transition: 0.25s ease-in-out;
      //   -moz-transition: 0.25s ease-in-out;
      //   -o-transition: 0.25s ease-in-out;
      //   transition: 0.25s ease-in-out;
      // }
      // .line2 {
      //   -webkit-transition: 0.25s ease-in-out;
      //   -moz-transition: 0.25s ease-in-out;
      //   -o-transition: 0.25s ease-in-out;
      //   transition: 0.25s ease-in-out;
      // }
      // .line3 {
      //   max-width: 50%;
      //   -webkit-transition: 0.25s ease-in-out;
      //   -moz-transition: 0.25s ease-in-out;
      //   -o-transition: 0.25s ease-in-out;
      //   transition: 0.25s ease-in-out;
      // }
      &.active {
        top: 60%;
        .line {
          background-color: #022249;
        }
        .line1 {
          max-width: 100%;
          top: 18px;
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          transform: translateY(0.075em) rotate(135deg);
        }

        .line2 {
          max-width: 100%;
          opacity: 0;
          left: -60px;
        }

        .line3 {
          max-width: 100%;
          -webkit-transform: rotate(-135deg);
          -moz-transform: rotate(-135deg);
          -o-transform: rotate(-135deg);
          transform: translateY(-1em) rotate(-135deg);
        }
      }
      // &.black {
      //   .line {
      //     background-color: #000000;
      //   }
      // }
    }
  }
}
