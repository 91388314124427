.com_banner_sec1 {
  .banner_container {
    position: relative;
    margin: 0 0 -1px;
    .banner_img {
      width: 100%;
      max-width: 100%;
    }
    .breadcrumb {
      position: absolute;
    //   top: 9em;
      top: 2.4em;
      left: initial;
      margin: 0;
      z-index: 2;
      .custom_breadcrumb {
        display: flex;
        align-items: center;
      }
      .home_url {
        color: #ffffff;
        font-size: 1.125em;
        font-family: "Montserrat";
        font-weight: 400;
        letter-spacing: 0px;
        line-height: normal;
        // pointer-events: none;
        display: block;
      }
      .disable_link {
        pointer-events: none;
      }
      .home_icon {
        width: 100%;
        max-width: 1.25em;
        margin-right: 0.5em;
        object-fit: contain;
        // display: block;
        vertical-align: text-top;
        cursor: pointer;
      }
    }
    .banner_content_container {
      position: absolute;
      left: 0;
      right: 0;
      top: 4.9em;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .heading {
        color: #ffffff;
        font-size: 5.625em;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 1.5667;
        text-transform: uppercase;
        position: relative;
        &::before {
          content: "";
          background: url("../../images/fixture/white-head-bottom.png")
            no-repeat;
          background-size: 100% 100%;
          width: 1.03em;
          height: 0.51623em;
          position: absolute;
          bottom: -0.2em;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .com_banner_sec1 {
    .banner_container {
      .breadcrumb {
        top: 8.5em;
        // top: 2.7em;
      }
      .banner_content_container {
        .heading {
          font-size: 3.6em;
          &::before {
            width: 1.1em;
            height: 0.6em;
            bottom: -0.25em;
          }
        }
      }
      .banner_img {
        height: 24em;
        // height: 18em;
      }
    }
  }
}
