.footer_sec1 {
  padding: 2.9375em 0 0;
  background-color: #031933;
  .footer_row {
    border-bottom: 1px solid #ffffff4d;
    margin: 0 auto;
    padding-bottom: 8.2356em;
    position: relative;
  }
  // .footer_col {
  //     padding: 0;
  // }
  .logo_wrapper {
    width: 100%;
    max-width: 6.8125em;
    .footer_logo {
      width: 100%;
      max-width: 100%;
    }
  }

  .heading {
    color: #fbd521;
    font-size: 1.5em;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 1.125;
    margin-bottom: 2.34375em;
  }

  .info_wrapper {
    .contact_wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 2.045em;
      width: 100%;
      // max-width: 15em;
      &:last-child {
        margin-bottom: 0;
      }
      .mail_icon {
        width: 100%;
        max-width: 1.61625em;
        height: 100%;
        margin-right: 1em;
        margin-top: 0.25em;
        display: block;
        object-fit: contain;
      }
      .info_text {
        color: #ffffff;
        font-size: 1.25em;
        font-weight: 300;
        letter-spacing: 0px;
        line-height: 1.35;
      }
      .location_icon {
        width: 100%;
        max-width: 1.625em;
        height: 100%;
        margin-right: 1em;
        display: block;
        margin-top: 0.25em;
        object-fit: contain;
      }
    }
  }

  .social_wrapper {
    display: flex;
    align-items: center;
    .social_link {
      margin-right: 1.9731em;
    }
    .social_icon {
      width: 100%;
      max-width: 1.6875em;
      height: 100%;
      max-height: 1.6875em;
      object-fit: contain;
      //   margin-right: 1.63125em;
      //   margin-right: 1.9731em;
    }
  }

  .copyright_wrapper {
    padding: 2.045em 0 4.5482em;
    .copy_text {
      color: #ffffff;
      font-size: 1.25em;
      font-weight: 300;
      letter-spacing: 0px;
      line-height: 1.6;
    }
  }
  .yellow_strip {
    width: 100%;
    max-width: 100%;
    height: 0.4375em;
    background-color: #fbd521;
  }
}

@media (max-width: 767px) {
  .footer_sec1 {
    padding: 3.3em 0 0;
    overflow: hidden;
    margin-bottom: 4.9em;
    .footer_row {
      padding-bottom: 3em;
      border-bottom: none;
      // overflow: hidden;
      &::before {
        content: "";
        width: calc(100% + 3.5em);
        max-width: calc(100% + 3em);
        height: 1px;
        position: absolute;
        left: -1.5em;
        bottom: 0;
        background-color: #ffffff4d;
      }
    }
    .footer_col {
      padding: 0 1.5em 0 1.7em;
    }
    .logo_wrapper {
      max-width: 7.4em;
      margin-bottom: 2em;
    }

    .heading {
      font-size: 1.8em;
      line-height: 1.5;
      margin-bottom: 1.4445em;
    }

    .info_wrapper {
      .contact_wrapper {
        margin-bottom: 2.8em;
        .mail_icon {
          max-width: 1.493em;
          margin-right: 1.6em;
          margin-top: 0.8em;
        }
        .location_icon {
          max-width: 1.5em;
          margin-right: 1.6em;
          margin-top: 0.5em;
        }
        .info_text {
          font-size: 1.6em;
          line-height: 1.6875;
          padding-right: 1em;
          br {
            display: none;
          }
        }
      }
    }

    .social_wrapper {
      .social_link {
        margin-right: 3em;
      }
      .social_icon {
        max-width: 2.1em;
      }
    }

    .getintouch_wrapper {
      margin-top: 2.9em;
    }

    .copyright_wrapper {
      padding: 1em 0 3.4em;
    }
  }
}
