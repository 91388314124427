.media_sec1 {
  margin-top: 8em;
}

.media_sec2 {
  position: relative;
  padding: 6.5625em 0 6.25em;
  .my_container {
    padding: 0;
  }
  .yellow_back_img {
    width: 100%;
    max-width: 100%;
    // height: 100%;
    min-height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    background-color: #f7c439;
  }

  .heading {
    color: #022249;
    font-size: 3.375em;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: normal;
    margin-bottom: 0.889em;
  }

  .media_box {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 1.875em;
    position: relative;
    .media_img {
      border-radius: 1.875em;
      width: 100%;
      max-width: 100%;
    }
    .media_logo {
      position: absolute;
      right: 2.5em;
      top: 2.5em;
      max-width: 14.25em;
      object-fit: contain;
    }
    .content_wrapper {
      position: absolute;
      left: 2.5em;
      right: 2.5em;
      bottom: 2.5em;
      //   width: 100%;
      max-width: 100%;
      .media_title {
        color: #ffffff;
        font-size: 2.375em;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 1.31579;
        margin-bottom: 0.3948em;
        width: 100%;
        max-width: 25.29em;
      }
      .link_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .media_link {
        color: #ffffff;
        font-size: 1.5em;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: normal;
        text-decoration: underline;
      }
      .media_date {
        color: #ffffff;
        font-size: 2em;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 1.5;
      }
    }
  }

  .media_row {
    margin: 0 auto;
  }

  .media_col {
    padding: 0;
    margin-top: 2.5em;
    &:nth-child(3n + 2) .media_box1 {
      margin: 0 auto;
    }
    &:nth-child(3n + 3) .media_box1 {
      margin-left: auto;
    }
  }

  .media_box1 {
    border-radius: 1.875em;
    width: 100%;
    // max-width: 96.47%;
    max-width: 95.75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .img_wrapper {
      width: 100%;
      position: relative;
      margin: 0;
      padding: 0;
      .media_img {
        border-radius: 1.875em 1.875em 0em 0em;
        width: 100%;
        max-width: 100%;
      }
      .media_logo {
        position: absolute;
        right: 1.875em;
        top: 1.25em;
        max-width: 9.5em;
        object-fit: contain;
      }
    }

    .content_wrapper {
      // background-color: #ffffff;
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 0em 0em 1.875em 1.875em;
      border: 1px solid #022249e6;
      padding: 1.5em 1.875em 2.5625em;
      width: 100%;
      max-width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .media_title {
        color: #022249;
        font-size: 1.375em;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 1.401;
        margin-bottom: 1.5em;
      }
      .link_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .media_link {
        color: #022249;
        font-size: 1.125em;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: normal;
        text-decoration: underline;
      }
      .media_date {
        color: #00000099;
        font-size: 1.25em;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 1.55;
      }
    }
  }
}

@media (max-width: 991px) {
  .media_sec2 {
    .media_col {
      &:nth-child(3n + 2) .media_box1 {
        margin: 0;
      }
      &:nth-child(3n + 3) .media_box1 {
        margin-left: 0;
      }

      &:nth-child(2n + 1) .media_box1 {
        padding-right: 1.2em;
      }
      &:nth-child(2n + 2) .media_box1 {
        padding-left: 1.2em;
      }
    }

    .media_box1 {
      max-width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .media_sec1 {
    margin-top: 0;
  }

  .media_sec2 {
    padding: 6em 0;
    .my_container {
      padding: 0 15px;
    }
    .heading {
      font-size: 2.8em;
      letter-spacing: 0px;
      line-height: 1.3572;
      margin-bottom: 1.4285em;
    }

    .media_box {
      border-radius: 1.2em;
      .media_img {
        border-radius: 1.2em;
      }
      .media_logo {
        max-width: 8.9em;
        right: 2em;
        top: 2em;
      }
      .content_wrapper {
        left: 2em;
        right: 2em;
        bottom: 2em;
        .media_title {
          font-size: 1.6em;
          letter-spacing: 0px;
          line-height: 1.625;
          margin-bottom: 1em;
        }
        .media_link {
          font-size: 1.4em;
          letter-spacing: 0px;
          line-height: normal;
        }
        .media_date {
          font-size: 1.6em;
          letter-spacing: 0px;
          line-height: normal;
        }
      }
    }

    .media_col {
      margin-top: 4em;
    }

    .media_box1 {
      border-radius: 1.2em;
      .img_wrapper {
        .media_img {
          border-radius: 1.2em 1.2em 0em 0em;
        }
        .media_logo {
          max-width: 9.7em;
          right: 2em;
          top: 2em;
        }
      }
      .content_wrapper {
        border-radius: 0em 0em 1.2em 1.2em;
        padding: 2em 2em;
        .media_title {
          font-size: 1.6em;
          letter-spacing: 0px;
          line-height: 1.125;
          margin-bottom: 1em;
        }
        .media_link {
          font-size: 1.4em;
          letter-spacing: 0px;
          line-height: normal;
        }
        .media_date {
          font-size: 1.6em;
          letter-spacing: 0px;
          line-height: 1.1875;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .media_sec2 {
    .media_col {
      &:nth-child(2n + 1) .media_box1 {
        padding-right: 0;
      }
      &:nth-child(2n + 2) .media_box1 {
        padding-left: 0;
      }
    }
  }
}
