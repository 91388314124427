.squad_sec1 {
  margin-top: 6.6em;
  //   .com_banner_sec1 {
  //     .banner_container {
  //       .breadcrumb {
  //         top: 2.4em;
  //       }
  //     }
  //   }
}

.squad_sec2 {
  //   padding: 7.6875em 0 8.2769em;
  padding: 3.21em 0 8.2769em;
  position: relative;

  .tabs_wrapper {
    display: flex;
    justify-content: end;

    .tab_button {
      border: 1px solid #022249;
      border-radius: 50px;
      background-color: transparent;
      color: #022249;
      font-size: 1.5em;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 1.285;
      padding: 0.4704em 0.5em;
      transition: 0.5s ease-in-out;
      min-width: 6.54167em;
    }

    & :nth-child(1) {
      margin-right: 1.5em;
    }

    .active {
      background-color: #022249;
      border: 1px solid #022249;
      color: #ffffff;
      transition: 0.5s ease-in-out;
    }
  }

  .my_container {
    padding: 0;
  }

  .yellow_back_img {
    width: 100%;
    max-width: 100%;
    // height: 100%;
    min-height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    background-color: #f7c439;
  }

  .squad_row {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;

    .squad_col {
      padding: 0;
      margin-top: 4.5em;
    }
  }

  .content_box {
    position: relative;
    width: 100%;
    max-width: 21.0775em;
    height: 100%;
    padding-bottom: 1.5em;
    // padding-bottom: 2.942em;
    margin: 0 auto;

    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: flex-end;
    // margin-right: 6.9225em;
    // &:nth-child(4n+4) {
    //     margin-right: 0;
    // }
    .back_img {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      max-width: 100%;
      z-index: -1;
    }

    .squad_img {
      width: 100%;
      max-width: 18.962em;
      height: 21.125em;
      display: block;
      margin: 0 auto;
    }

    .name {
      color: #022249;
      font-size: 2em;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 1.3125;
      margin: 1.10375em 0 0.09375em;
      text-align: center;
      text-transform: uppercase;
    }

    .desc {
      color: #000000;
      font-size: 1.5em;
      font-weight: 300;
      letter-spacing: 0px;
      line-height: 1.584;
      text-align: center;
    }

    .pers_details {
      color: #000000;
      font-size: 1.5em;
      font-weight: 300;
      letter-spacing: 0px;
      line-height: 1.584;
      text-align: center;
    }
  }
}

@media (max-width: 1536px) {
  .squad_sec2 {

    // .squad_row {
    //   max-width: 92%;
    // }
    .content_box {
      max-width: 24em;

      .squad_img {
        max-width: 22.5em;
        height: 24.3em;
      }
    }
  }
}

@media (max-width: 1440px) {
  .squad_sec2 {
    .content_box {
      max-width: 23em;

      .squad_img {
        max-width: 21em;
        height: 22.5em;
      }
    }
  }
}

@media (max-width: 1080px) {
  .squad_sec2 {
    .content_box {
      max-width: 21em;

      .squad_img {
        max-width: 18.962em;
        height: 21.125em;
      }
    }
  }
}

@media (max-width: 992px) {
  .squad_sec2 {
    .squad_row {
      .squad_col {
        // width: 33.33333333%;
        flex: 0 0 33.33333333%;
      }
    }
  }
}

@media (max-width: 991px) {
  .squad_sec2 {
    .squad_row {
      .squad_col {
        flex: 0 0 auto;
      }
    }
  }
}

@media (max-width: 767px) {
  .squad_sec1 {
    // margin-top: 5.8em;
    margin-top: 0;
    // .com_banner_sec1 {
    //   .banner_container {
    //     .breadcrumb {
    //       top: 2.7em;
    //     }
    //     .banner_img {
    //       height: 18em;
    //     }
    //   }
    // }
  }

  .squad_sec2 {
    .my_container {
      padding: 0 15px;
    }

    .squad_row {
      max-width: 90%;

      .squad_col {
        flex: 0 0 auto;
      }
    }

    .content_box {
      max-width: 24em;

      .squad_img {
        max-width: 20em;
        height: 22em;
      }

      .name {
        font-size: 2.4em;
      }

      .desc {
        font-size: 1.8em;
      }

      .pers_details {
        font-size: 1.8em;
      }
    }
  }
}

@media (max-width: 600px) {
  .squad_sec2 {
    padding: 3em 0 8em;

    .squad_row {
      max-width: 100%;

      .squad_col {
        margin-top: 5.5em;
      }
    }

    .content_box {
      max-width: 75%;

      .squad_img {
        max-width: 85%;
        // height: 100%;
        height: auto;
      }
    }
  }
}