.fixture_main {
  .yellow_back_img {
    width: 100%;
    max-width: 100%;
    // height: 100%;
    min-height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    background-color: #f7c439;
  }
}

.fixture_sec1 {
  .banner_container {
    position: relative;
    .banner_img {
      width: 100%;
      max-width: 100%;
    }
    .breadcrumb {
      position: absolute;
      top: 9em;
      // left: 7.25em;
      left: initial;
      margin: 0;
      z-index: 2;
      .custom_breadcrumb {
        display: flex;
        align-items: center;
      }
      .home_url {
        color: #ffffff;
        font-size: 1.125em;
        font-family: "Montserrat";
        font-weight: 400;
        letter-spacing: 0px;
        line-height: normal;
        pointer-events: none;
        display: block;
      }
      .home_icon {
        width: 100%;
        max-width: 1.25em;
        margin-right: 0.5em;
        object-fit: contain;
        // display: block;
        vertical-align: text-top;
        cursor: pointer;
      }
    }
    .banner_content_container {
      position: absolute;
      left: 0;
      right: 0;
      top: 4.9em;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .heading {
        color: #ffffff;
        font-size: 5.625em;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 1.5667;
        text-transform: uppercase;
        position: relative;
        &::before {
          content: "";
          background: url("../../images/fixture/white-head-bottom.png")
            no-repeat;
          background-size: 100% 100%;
          width: 1.03em;
          height: 0.51623em;
          position: absolute;
          bottom: -0.2em;
        }
      }
    }
  }
}

.fixture_sec2 {
  position: relative;
  .title {
    color: #022249;
    text-transform: uppercase;
    margin-bottom: 0.352em;
  }
  .schedule_box_container {
    padding: 6.5625em 7.25em 11.05625em 8.14063em;
    position: relative;
    // border-bottom: 1px solid #FFFFFF;
    &::before {
      content: "";
      background-color: #ffffff;
      width: 100%;
      max-width: 96.2345%;
      height: 1px;
      margin: 0 auto;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .schedule_container {
    padding-bottom: 5.0875em;
    &:last-child {
      padding-bottom: 0;
    }
  }
  .details_wrapper {
    border: 1px solid #000000;
    // border-radius: 0px 31px 31px 0px;
    border-radius: 0px 1.9375em 1.9375em 0px;
    background-color: #ffffff80;
    display: flex;
    justify-content: flex-start;
    height: 100%;
    // max-height: 11.725em;
    max-height: 9.771vw;
    position: relative;
    &::before {
      content: "";
      width: 6px;
      height: calc(100% + 1.3375em);
      position: absolute;
      left: -0.8em;
      top: 0;
      background-color: #022249;
    }
  }
  .time_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // border-radius: 0px 31px 31px 0px;
    border-radius: 0px 1.9375em 1.9375em 0px;
    background-color: #022249;
    box-shadow: -23px 17px 6px #00000029;
    width: 100%;
    max-width: 43.72375em;
    padding: 3.312em 1em 3.312em 4.515625em;
    .title {
      color: #fbd521;
      font-weight: 400;
      margin: 0 0.1852em 0 0;
    }
    .time {
      color: #ffffff;
      display: inline-block;
      font-size: 1.875em;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: normal;
      border-bottom: 1px solid #ffffff;
      border-top: 1px solid #ffffff;
    }
  }
  .logo_wrapper {
    width: 100%;
    max-width: 100%;
    // margin-left: 9.375em;
    margin-left: 9.014%;
    .img_wrapper {
      //   position: absolute;
      //   top: 1.2em;
      //   left: 0;
      // position: relative;
      // top: 3.2em;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 2.6em;
    }
    .team_logo {
      width: 100%;
      // max-width: fit-content;
      max-width: 12.5625em;
      // margin-left: 7.5em;
    }
    .mumbai_logo {
      // max-width: 12.5625em;
      max-width: 24.6755%;
    }
    .singams_logo {
      // max-width: 9.1875em;
      max-width: 18.0466%;
    }
    .vs_img {
      width: 100%;
      // max-width: 5.74125em;
      // max-width: 11.276%;
      max-width: 4.785vw;
      // margin: 2em 7.5em 0 4em;
      margin: 2em 14.733% 0 7.858%;
    }

    .text_wrapper {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      height: 100%;
      padding: 0 2.5em 0 0;
      .winner_name {
        color: #022249;
        font-size: 3.375em;
        font-weight: 500;
        line-height: 1.482;
        position: relative;
      }
      .vs_img {
        // margin: 0;
        margin: 0 7% 0 7%;
      }
    }
  }
  .divider_wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1.5em;
    text-align: center;
    .bat_img {
      width: 100%;
      // max-width: 26.72em;
      max-width: 22.3597%;
    }
  }
}

.fixture_sec3 {
  position: relative;
  .schedule_box_container {
    padding: 9.15625em 7.25em 11.2375em 8.14063em;
    position: relative;
    // border-bottom: 1px solid #FFFFFF;
  }
  .schedule_container {
    padding-bottom: 8.61125em;
    &:last-child {
      padding-bottom: 0;
    }
  }
  .details_wrapper {
    border: 1px solid #000000;
    // border-radius: 0px 31px 31px 0px;
    border-radius: 0px 1.9375em 1.9375em 0px;
    background-color: #ffffff80;
    display: flex;
    justify-content: flex-start;
    height: 100%;
    // max-height: 9.13875em;
    max-height: 7.615vw;
    position: relative;
    &::before {
      content: "";
      width: 6px;
      // height: 9.125em;
      height: 100%;
      position: absolute;
      left: -0.8em;
      top: 0;
      background-color: #022249;
    }
  }
  .time_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // border-radius: 0px 31px 31px 0px;
    border-radius: 0px 1.9375em 1.9375em 0px;
    background-color: #ffffff;
    box-shadow: -23px 17px 6px #00000029;
    width: 100%;
    max-width: 43.72375em;
    padding: 3.312em 1em 3.312em 4.515625em;
    position: relative;
    // z-index: 2;
    // .background_white {
    //   background-color: #ffffff;
    //   position: absolute;
    //   height: calc(100% + 0.125em);
    //   width: 100%;
    //   left: 0;
    //   right: 0;
    //   top: 0;
    //   bottom: 0;
    //   // z-index: -1;
    //   border-radius: 0px 1.9375em 1.9375em 0px;
    // }
    .title {
      color: #022249;
      font-weight: 400;
      margin: 0 0.1852em 0 0;
    }
    .time {
      color: #000000;
      display: inline-block;
      font-size: 1.875em;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: normal;
      border-bottom: 1px solid #707070;
      border-top: 1px solid #707070;
      position: relative;
      z-index: 2;
    }
  }
  .logo_wrapper {
    width: 100%;
    max-width: 100%;
    // margin-left: 9.375em;
    margin-left: 9.014%;
    .img_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // margin-top: 4.5em;
      height: 100%;
    }
    .team_logo {
      width: 100%;
      // max-width: fit-content;
      max-width: 11.625em;
    }
    .singams_logo {
      // max-width: 16.574%;
      max-width: 5.56vw;
    }
    .tigers_logo {
      // max-width: 14.118%;
      max-width: 5vw;
      margin-top: 0.5em;
    }
    .banglore_logo {
      // max-width: 22.835%;
      max-width: 9vw;
    }
    .mumbai_logo {
      // max-width: 21.607%;
      max-width: 8vw;
      margin-top: 1em;
    }
    .srinagar_logo {
      // max-width: 21.116%;
      max-width: 8.5vw;
      margin-top: 0.75em;
    }
    .falcon_logo {
      // max-width: 29.99%;
      max-width: 10.3vw;
      margin-top: 1em;
    }
    .vs_img {
      width: 100%;
      // max-width: 4.785vw;
      max-width: 3.75vw;
      // margin: 0.5em 8% 0 13.85%;
      margin: 0 3.5vw 0 6vw;
    }
    .vs_img2 {
      // margin: 0 16.155% 0 10.5%;
      margin: 0 7vw 0 4.75vw;
    }
    .vs_img3 {
      // margin: 0 13% 0 14.5%;
      margin: 0 5.75vw 0 6.25vw;
    }
    .vs_img4 {
      margin: 0 16.5% 0 18%;
    }
    .vs_img5 {
      // margin: 0 16.5% 0 15.5%;
      margin: 0 7.5vw 0 6.7vw;
    }
    .vs_img6 {
      // margin: 0 16.5% 0 10.25%;
      margin: 0 7.5vw 0 4.5vw;
    }
  }
  .logo_wrapper3 {
    margin-left: 6.5%;
  }
  .logo_wrapper4 {
    margin-left: 8.75%;
  }
  .logo_wrapper5 {
    // margin-left: 6.72563em;
    margin-left: 8%;
  }
  .logo_wrapper6 {
    // margin-left: 6.72563em;
    margin-left: 9%;
  }
}

@media (max-width: 1194px) {
  .fixture_sec2 {
    .logo_wrapper {
      .text_wrapper {
        .winner_name {
          font-size: 2.4em;
          line-height: normal;
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  .fixture_sec3 {
    // .logo_wrapper {
    //   .img_wrapper {
    //     margin-top: 2.25em;
    //   }
    // }
    .time_wrapper {
      max-width: 39em;
    }
  }
}

@media (max-width: 991px) {
  .fixture_sec2 {
    .title {
      // font-size: 2.75em;
      font-size: 2.5em;
    }
    .time_wrapper {
      max-width: 30em;
      padding: 3.312em 1em 3.312em 2.5em;
      .title {
        // font-size: 2.75em;
        font-size: 2.5em;
      }
    }
  }
  .fixture_sec3 {
    .title {
      // font-size: 2.75em;
      font-size: 2.5em;
    }
    .time_wrapper {
      max-width: 30em;
      padding: 3.312em 1em 3.312em 2.5em;
      .title {
        // font-size: 2.75em;
        font-size: 2.5em;
      }
    }
  }
}

@media (max-width: 833px) {
  .fixture_sec2 {
    .schedule_box_container {
      padding: 6.5625em 2.5em 11.05625em 2.5em;
    }
  }
  .fixture_sec3 {
    .schedule_box_container {
      padding: 6.5625em 2.5em 11.05625em 2.5em;
    }
  }
}

@media (max-width: 767px) {
  .fixture_sec1 {
    .banner_container {
      .breadcrumb {
        top: 8.5em;
        // left: 2em;
      }
      .banner_content_container {
        .heading {
          font-size: 3.6em;
          &::before {
            width: 1.1em;
            height: 0.6em;
            bottom: -0.25em;
          }
        }
      }
      .banner_img {
        height: 24em;
      }
    }
  }

  .fixture_sec2 {
    .schedule_box_container {
      padding: 6.5625em 1.5em 11.05625em 1.5em;
    }
    .schedule_container {
      overflow: hidden;
    }
    .details_wrapper {
      // max-height: 9em;
      max-height: 100%;
      flex-direction: column;
    }
    .time_wrapper {
      // max-width: 39%;
      max-width: 100%;
      // padding: 2.25em 1em 2.25em 1em;
      padding: 2.25em 1em 2.25em 2.5em;
      border-radius: 0px 1.85em 1.85em 0px;
      .title {
        font-size: 2.8em;
        margin: 0 0.4em 0 0;
      }
      .time {
        font-size: 2em;
      }
    }

    .logo_wrapper {
      margin-left: 1em;
      .img_wrapper {
        align-items: center;
      }
      .team_logo {
        max-width: 10em;
      }
      .mumbai_logo {
        max-width: 10em;
      }
      .singams_logo {
        max-width: 9em;
      }
      .vs_img {
        max-width: 5.25em;
        margin: 0.5em 3em 0 2.5em;
      }

      .text_wrapper {
        padding: 2.6em 2.5em 0 0;
      }
    }
    .divider_wrapper {
      bottom: -1.25em;
      .bat_img {
        max-width: 22.72em;
      }
    }
  }

  .fixture_sec3 {
    .schedule_box_container {
      padding: 6.5625em 1.5em 11.05625em 1.5em;
    }
    .schedule_container {
      overflow: hidden;
    }
    .details_wrapper {
      // max-height: 9em;
      max-height: 100%;
      flex-direction: column;
      &::before {
        height: 100%;
      }
    }
    .time_wrapper {
      // max-width: 39%;
      max-width: 100%;
      // padding: 2.25em 1em 2.25em 1em;
      padding: 2.25em 1em 2.25em 2.5em;
      // border-radius: 0px 1.8em 1.8em 0px;
      .title {
        font-size: 2.8em;
        margin: 0 0.4em 0 0;
      }
      .time {
        font-size: 2em;
      }
    }
    .logo_wrapper {
      margin-left: 2em;
      .img_wrapper {
        align-items: center;
        margin-top: 2.25em;
      }
      .team_logo {
        max-width: 10em;
      }
      .mumbai_logo {
        max-width: 10em;
        margin-top: 0;
      }
      .singams_logo {
        max-width: 9em;
      }
      .falcon_logo {
        margin-top: 0;
      }
      .banglore_logo {
        margin-top: 0;
      }
      .srinagar_logo {
        margin-top: 0;
      }
      .tigers_logo {
        margin-top: 0;
      }
      .vs_img {
        max-width: 5.25em;
        margin: 0.5em 3em 0 2.5em;
      }
    }
  }
}
